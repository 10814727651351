.customDesign {
    width: 100vw;
}
.customHeader {
    width: 100vw;
}
.customHeader img {
   width: 100%;
   height: 40vh;
}
.designPath {
    padding-top: 40px;
 
    margin-bottom: 30px;
}
.cloth {
    display: flex;
    flex-direction: row;
    margin-left: 3vw;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px 5px #888;
    height: 100px;
    width: 90vw;
  }
  .clothIcon {
    height: 15vmin;
    width: 15vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .cloth h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .clothCircle {
    background: black;
    color: #ffd700;
    border-radius: 30px;
    margin-right: 30px;
    width: 120px;
  }
  .style {
    display: flex;
    flex-direction: row;
    margin-left: 3vw;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px 5px #888;
    height: 100px;
    width: 90vw;
  }
  .styleIcon {
    height: 15vmin;
    width: 15vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .style h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .styleCircle {
    background: black;
    color: #ffd700;
    border-radius: 30px;
    margin-right: 30px;
    width: 120px;
  }
  .measurements {
    display: flex;
    flex-direction: row;
    margin-left: 3vw;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px 5px #888;
    height: 100px;
    width: 90vw;
  }
  .measurementsIcon {
    height: 15vmin;
    width: 15vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .measurements h2 {
    display: flex;
    align-items: center;
    justify-content: center;   
  }
  .measurementsCircle {
    background: black;
    color: #ffd700;
    border-radius: 30px;
    margin-right: 0px;
    width: 160px;
  }
  .magick {
    display: flex;
    flex-direction: row;
    margin-left: 3vw;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px 5px #888;
    height: 100px;
    width: 90vw;
  }
  .magickIcon {
    height: 15vmin;
    width: 15vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .magick h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .magickCircle {
    background: black;
    color: #ffd700;
    border-radius: 30px;
    margin-right: 20px;
    width: 160px;
  }
  .measurementsIcon, .styleIcon, .magickIcon, .downArrow{
    height: 15vmin;
    width: 15vmin;
  }
  .customCards {
    background-color: black;
    color: white;
    padding-bottom: 50px;

  }
  .whyWeRock {
    background-color: black;
    color: #ffd700;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    
  }
  .whyWeRock h1 {
    color: white;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .customCards h2{
    background: black;
    color: #ffd700;
    padding-bottom: 15px;
   
  }
 
  .firstCustomCard {
    position: relative;
    height: 400px;
    width: 70vw;
    margin-bottom: 30px;
    margin-left: 5vw;
    margin-top: 20px;
    text-align: start;
  }
  
  .firstCustomCard img, .secondCustomCard img, .thirdCustomCard img, .fourthCustomCard img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    margin-top: 5px;
   
  }
   .founderCustomCard img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 5px;
  }
  .founderCustomCard {
    position: relative;
    height: 400px;
    width: 70vw;
    margin-bottom: 150px;
    margin-left: 5vw;
    margin-top: 100px;
    text-align: start;
  }
  .secondCustomCard {
    position: relative;
    height: 400px;
    width: 70vw;
    margin-left: 25vw;
    margin-bottom: 30px;
    padding-top: 30px;
    text-align: start;
  }
  .thirdCustomCard {
    position: relative;
    height: 400px;
    width: 70vw;
    margin-bottom: 30px;
    margin-left: 5vw;
    padding-top: 30px;  
    margin-top: 50px;
    text-align: start;
}
  
  .fourthCustomCard {
    position: relative;
    height: 400px;
    width: 70vw;
    margin-left: 25vw;
    margin-bottom: 30px;
    padding-top: 40px;
    text-align: start;
  }
  @media screen and (min-width: 960px) {
    .customHeader img {
      width: 100%;
      height: 60vh;
      object-fit: cover;
   }
   .cloth {
   
    margin-top: 120px;
   
    height: 150px;
    width: 35vw;
  }
 
  
  .clothCircle {
    margin-right: 30px;
    width: 150px;
  }
  .style { 
    margin-left: 23vw;
    margin-top: 100px; 
    height: 150px;
    width: 35vw;
  }
  
  .styleCircle {
    margin-right: 30px;
    width: 150px;
  }
  .measurements {
    margin-left: 43vw;
    margin-top: 100px;
    height: 150px;
    width: 35vw;
  }
  
  .measurementsCircle {
    margin-right: 30px;
    width: 160px;
  }
  .magick {
    margin-left: 63vw;
    margin-bottom: 100px;
    margin-top: 100px;
    height: 150px;
    width: 35vw;
  }

  .magickCircle {
    margin-right: 50px;
    width: 160px;
  }
  
  .downArrow {
    display: none;
  }
  .customCards {
    background-color: black;
    color: white;
    padding-bottom: 50px;

  }
  .whyWeRock {
    background-color: black;
    color: #ffd700;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    
  }
  .whyWeRock h1 {
    color: white;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .customCards h2{
    background: black;
    color: #ffd700;
    padding-bottom: 15px;
   
  }
 
  .firstCustomCard {
    position: relative;
    height: 500px;
    width: 45vw;
    margin-bottom: 30px;
    margin-left: 5vw;
    margin-top: 20px;
    text-align: start;
  }
  
  .firstCustomCard img, .secondCustomCard img, .thirdCustomCard img, .fourthCustomCard img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 5px;
   
  }
  
  
  .secondCustomCard {
    position: relative;
    height: 500px;
    width: 45vw;
    margin-left: 50vw;
    margin-right: 5vw;
    margin-bottom: 30px;
    padding-top: 30px;
    text-align: start;
  }
  .thirdCustomCard {
    height: 500px;
    width: 44vw;
  
}
  
  .fourthCustomCard {
    height: 500px;
    width: 45vw;
    margin-left: 50vw;
    margin-right: 5vw;
  }
  .founderCustomCard {
    position: relative;
    height: 500px;
    width: 45vw;
    margin-bottom: 150px;
    margin-left: 5vw;
    margin-top: 100px;
    text-align: start;
  }
  .founderCustomCard img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    margin-top: 5px;
  }
  .wechatimg img {
    object-fit: contain;
  }
    }
  