* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
.body {
  width: 100%;
  min-height: 100%;
  margin: 0px;
  padding: 0px;  
  margin-bottom: 150px;
}
.App {
  text-align: center;
  height: 100%;
  width:100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
.slider {
  position: relative;
  z-index: -1;
  width: 100vw;
  height: 400px;
  top: 0;
}
.image1 {
 
  height: 400px;
  width: 100vw;
}
.image1 img {
  object-fit: cover;
  width: 100vw;
  height: 400px;
}
.image2 {
  width: 100vw;
  height: 400px;
}
.image2 img {
  object-fit: cover;
  width: 100vw;
  height: 400px;
}
.image3 {
  width: 100vw;
  height: 400px;
}
.image3 img {
  object-fit: cover;
  width: 100vw;
  height: 400px;
}
.image4 {
  width: 100vw;
  height: 400px;
}
.image4 img {
  object-fit: cover;
  width: 100vw;
  height: 400px;
}

:root {
  --primary: #ffd700;
}
.welcome {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 20px;
  color: white;
  margin-bottom: 20px;
  position: relative;
}
.welcome p {
  padding: 5px;
}
.welcome h1{
margin: 10px;
}

.firstBtn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: black;
  cursor: pointer;
  background-color: var(--primary);
  margin-top: 15px;
  margin-bottom: 25px;
}

.firstBtn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: var(--primary);
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
.shopbyCollection h1 {
  padding-bottom: 20px;
  padding-top:20px;
  margin-bottom: 15px;
}

.women {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 20px;
  height: 300px;;
  width: 80vw;
}
.womenImg{
  height: 300px;;
  width: 100%;
  object-fit: cover;
}


.man {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 20px;
  height: 300px;;
  width: 80vw;
}
.manImg {
  height: 300px;;
  width: 100%;
  object-fit: cover;
}

.kids {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 20px;
  height: 300px;;
  width: 80vw;
}
.kidsImg {
  height: 300px;;
  width: 100%;
  object-fit: cover;
}

.accessories {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 20px;
  height: 300px;;
  width: 80vw;
}
.accesoriesImg {
  height: 300px;;
  width: 100%;
  object-fit: cover;
}
.featuredProducts {
  padding-top: 25px;
  padding-bottom: 20px;
  
}
.featuredProducts h1 {
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.productsGrid {
margin-bottom: 4rem;
padding-bottom: 20px;
display: grid;
column-gap: 15px;
row-gap: 5em;
grid-template-columns: 1fr 1fr;
}
.productsGrid p {
  color: #ffd700;
  background-color: black;
}
.item1 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-left: auto;
  margin-bottom: 20px;

}
.item1Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item2 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-right: auto;
  margin-bottom: 20px;
}

.item2Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item3 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-left: auto;
  margin-bottom: 20px;
}
.item3Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item4 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-right: auto;
  margin-bottom: 20px;
}

.item4Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item5 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-left: auto;
  margin-bottom: 20px;
}
.item5Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item6 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-right: auto;
  margin-bottom: 20px;
}

.item6Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item7 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-left: auto;
  margin-bottom: 20px;
}
.item7Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.item8 {
  position: relative;
  height: 250px;
  width: 170px;
  margin-right: auto;
  margin-bottom: 20px;
}

.item8Img {
  height: 250px;
  width: 170px;
  object-fit: cover;
}
.aboutCustomDesign {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: white;
}
.aboutCustomDesign h1 {
  font-weight: lighter;
  padding-bottom: 10px;
}
.aboutCustomDesignBtn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: black;
  cursor: pointer;
  background-color: var(--primary);
  margin-top: 20px;
  margin-bottom: 25px;
}
.aboutCustomDesignBtn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: var(--primary);
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
h1 {
  font-weight: lighter;
}
.customDisign {
  background-color: whitesmoke;
  padding: 10px;
  margin-top:10px;
  margin-bottom: 10px;
}
.winterCollection {
  padding-top: 30px;
  margin-top: 15px;
}
.winterCollection h1 {
  padding-bottom: 25px;
}

.customerReviews {
  padding-top: 20px;
  padding-bottom: 20px;
  padding: 20px;
}
.secondSlider {
  position: relative;
  border: 2px solid grey;
  border-radius: 18px;
  box-shadow: 10px 10px 10px 5px #888;
  margin-bottom: 5rem;
  margin-left: 15vw;
  margin-right: 15vw;
  padding-bottom:30px;
  width: 70vw;
  height: auto;
}
.card1 {
  height: 300px;
  width: 70vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card1 img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.card2 {
  height: 300px;
  width: 70vw;
}
.card2 img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.card3 {
  height: 300px;
  width: 70vw;
}
.card3 img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.card4 {
  height: 300px;
  width: 70vw;
}
.card4 img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.contactNeviCover {
  margin-top: 50px;
  height: 400px;
  padding-top: 20px;
  
}
.contactNevih1 {
 width: 86vw;
 margin-left: 7vw;
 margin-right: 7vw;
 border-bottom: 2px solid grey;
 padding-bottom: 15px;
}

.contactNevi h3 {
  padding-bottom: 25px;
  font-weight: lighter;
  padding-top: 15px;
  
}
.nameInput {
  display: block;
  margin-left: 7vw;
  width: 86vw;
  height: 4rem;
  margin-bottom: 15px;
  border: 2px solid grey;
  font-size: 1.3em;
}
.emailInput {
  display: block;
  margin-left: 7vw;
  width: 86vw;
  height: 4rem;
  margin-bottom: 15px;
  border: 2px solid grey;
  font-size: 1.3em;
}
.messegeInput {
  display: block;
  margin-left: 7vw;
  width: 86vw;
  height: 6rem;
  margin-bottom: 15px;
  border: 2px solid grey;
  font-size: 1.3em;
}
.emailLabel h4 {
  display: block;
  text-align: start;
  margin-left: 7vw;
  padding-bottom: 5px;
}
.nameLabel h4 {
  display: block;
  text-align: start;
  margin-left: 7vw;
  padding-bottom: 5px;
}
.massegeLabel h4 {
  display: block;
  text-align: start;
  margin-left: 7vw;
  padding-bottom: 5px;
}
 .nameInput::placeholder {
  font-weight: lighter;
  color: #999;
  padding-left: 20px;
  padding-bottom: 30px;
}
.emailInput::placeholder {
  font-weight: lighter;
  color: #999;
  padding-bottom: 40px;
  padding-left: 20px;
}
.messegeInput::placeholder {

  font-weight: lighter;
  color: #999;
  padding-top: 30px;
  padding-left: 20px;
}
.contactBtn {
  display: block;
  margin-left: 7vw;
  width: 86vw;
  height: 3.5rem;
  background-color: black;
  color: white;
  font-size: 1.1em;
  position:relative;
  
}
@media screen and (min-width: 960px) {
  .image1, .image1 img, .image2, .image2 img,
  .image3, .image3 img, .image4, .image4 img{
    width: 50vw;
    height: 600px;
    margin: 0;
    padding: 0;
  }
  .slider {
    height: 600px;
  }
  .theCollection {
    margin-bottom: 4rem;
    padding-bottom: 20px;
    display: grid;
    column-gap: 100px;
    row-gap: 35px;
    grid-template-columns: 1fr 1fr;
  }
 
  .women, .kids { 
    position: relative;
    height: 400px;
    width: 400px;
    margin-left: 15vw;
    margin-right: 15px;
  }
  .man, .accessories {
    position: relative;
    height: 400px;
    width: 400px;
   margin-right: 5vw;
   margin-left: 35px;
  }
  .women img, .man img, .kids img, .accessories img {
    height: 400px;
    width: 400px;
  }
  .productsGrid {
    margin-bottom: 4rem;
    padding-bottom: 20px;
    display: grid;
    column-gap: 15px;
    row-gap: 5em;
    grid-template-columns: 1fr 1fr 1fr;
    }
    .item1 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 150px;
      margin-bottom: 20px;
    }

    .item1Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item2 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 100px;
      margin-bottom: 20px; 
    }
    
    .item2Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item3 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 50px;
      margin-bottom: 20px;
    }
    .item3Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item4 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 150px;
      margin-bottom: 20px;
    }
    
    .item4Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item5 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 100px;
      margin-bottom: 20px;
    }
    .item5Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item6 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 50px;
      margin-bottom: 20px;
    }
    
    .item6Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item7 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 150px;
      margin-bottom: 35px;
    }
    .item7Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .item8 {
      position: relative;
      height: 350px;
      width: 270px;
      margin-left: 100px;
      margin-bottom: 20px;
    }
    
    .item8Img {
      height: 300px;
      width: 270px;
      object-fit: cover;
    }
    .secondSlider {
      position: relative;
      border: 2px solid grey;
      border-radius: 18px;
      box-shadow: 10px 10px 10px 5px #888;
      margin-bottom: 5rem;
      margin-left: 32vw;
      margin-right: 35vw;
      padding-bottom:30px;
      width: 35vw;
      height: auto;
    }
    .card1, .card2, .card3, .card4 {
      height: 550px;
      width: 30vw;
    }
    .card1 img, .card2 img, .card3 img, .card4 img {
      object-fit: cover;
      width: 100%;
      height: 550px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .contactNevih1 {
      width: 50vw;
      margin-left: 25vw;
      margin-right: 30vw;
      border-bottom: 2px solid grey;
      padding-bottom: 15px;
     }
     .contactNevih1 h1 {
       font-weight: lighter;
     }
     .contactNevi h3 {
       padding-bottom: 25px;
       font-weight: lighter;
       padding-top: 15px;
       
     }
     .nameInput {
       display: block;
       margin-left: 30vw;
       width: 40vw;
       height: 4rem;
       margin-bottom: 15px;
       border: 2px solid grey;
       font-size: 1.3em;
     }
     .emailInput {
       display: block;
       margin-left: 30vw;
       width: 40vw;
       height: 4rem;
       margin-bottom: 15px;
       border: 2px solid grey;
       font-size: 1.3em;
     }
     .messegeInput {
       display: block;
       margin-left: 30vw;
       width: 40vw;
       height: 6rem;
       margin-bottom: 15px;
       border: 2px solid grey;
       font-size: 1.3em;
     }
     .emailLabel h4 {
       display: block;
       text-align: start;
       margin-left: 30vw;
       padding-bottom: 5px;
     }
     .nameLabel h4 {
       display: block;
       text-align: start;
       margin-left: 30vw;
       padding-bottom: 5px;
     }
     .massegeLabel h4 {
       display: block;
       text-align: start;
       margin-left: 30vw;
       padding-bottom: 5px;
     }
      .nameInput::placeholder {
       font-weight: lighter;
       color: #999;
       padding-left: 20px;
       padding-bottom: 30px;
     }
     .emailInput::placeholder {
       font-weight: lighter;
       color: #999;
       padding-bottom: 40px;
       padding-left: 20px;
     }
     .messegeInput::placeholder {
     
       font-weight: lighter;
       color: #999;
       padding-top: 30px;
       padding-left: 20px;
     }
     .contactBtn {
       display: block;
       margin-left: 30vw;
       width: 40vw;
       height: 3.5rem;
       margin-bottom: 15px;
       background-color: black;
       color: white;
       font-size: 1.1em;
       margin-bottom: 20px;
     }
     .aboutCustomDesign p {
      margin-left: 30vw;
      margin-right: 30vw;
    }
    .welcome p {
      margin-left: 30vw;
      margin-right: 30vw;
    }

 }