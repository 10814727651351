.dropdown-menu {
    background: #1888ff;
    width: 200px;
    position: absolute;
    top: 80px;
    left: 60vw;
    right: 40vw;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    background: #ffd700;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #ffdd00;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }